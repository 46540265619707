import * as C from '../../common/lib/constants';
import * as R from './routesList';
import { PickByValue, ValuesType } from 'utility-types';
import { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import ChangeAddress from '../dynamic/settings/components/ChangeAddress/ChangeAddress';
import ChangeConsents from '../dynamic/settings/components/ChangeConsents/ChangeConsents';
import ChangeEmailOrPhone from '../dynamic/settings/components/ChangeEmailOrPhone/ChangeEmailOrPhone';
import ChangeEmailOrPhoneConfirm from '../dynamic/settings/components/ChangeEmailOrPhone/ChangeEmailOrPhoneConfirm';
import ChangeIdentityCardNumber from '../dynamic/settings/components/ChangeIdentityCardNumber';
import ChangePassword from '../dynamic/settings/components/ChangePassword/ChangePassword';
import ChangeSuccessModal from '../dynamic/settings/components/ChangeSuccessModal';
import UpdateDataModal from '@shared/components/UpdateDataModal';

type RouteName = ValuesType<PickByValue<typeof R, string>>

export const MODALS: Partial<Record<RouteName, () => ReactElement>> = {
  [R.APPLICATION_CHANGE_DATA]: () => <UpdateDataModal />,

  [R.CHANGE_ADDRESS]: ChangeAddress,
  [R.CHANGE_ADDRESS_SUCCESS]: () => <ChangeSuccessModal type={C.ADDRESS} />,
  [R.CHANGE_EMAIL]: () => <ChangeEmailOrPhone type={C.EMAIL} />,
  [R.CHANGE_EMAIL_CONFIRM]: () => <ChangeEmailOrPhoneConfirm type={C.EMAIL} />,
  [R.CHANGE_EMAIL_SUCCESS]: () => <ChangeSuccessModal type={C.EMAIL} />,
  [R.CHANGE_IDENTITY_CARD_NUMBER]: ChangeIdentityCardNumber,
  [R.CHANGE_ID_SUCCESS]: () => <ChangeSuccessModal type={C.ID} />,
  [R.CHANGE_PASSWORD]: ChangePassword,
  [R.CHANGE_PASSWORD_SUCCESS]: () => <ChangeSuccessModal type={C.PASSWORD} />,
  [R.CHANGE_PHONE]: () => <ChangeEmailOrPhone type={C.PHONE} />,
  [R.CHANGE_PHONE_CONFIRM]: () => <ChangeEmailOrPhoneConfirm type={C.PHONE} />,
  [R.CHANGE_PHONE_SUCCESS]: () => <ChangeSuccessModal type={C.PHONE} />,
  [R.CHANGE_CONSENTS]: ChangeConsents,
};

const Modals = ({
  pathname,
}: { pathname: RouteName }): ReactElement | null => {
  const Modal = MODALS[pathname];

  if (!Modal) {
    return null;
  }

  return <Route path={pathname} component={Modal} exact />;
};

export default Modals;
