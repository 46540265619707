import { AuthAction } from './authentication/actions';
import { CalculatorActions } from './calculator/actions';
import { createBrowserHistory, History } from 'history';
import { createStore, Store } from 'redux';
import { DashboardActions } from './dashboard/actions';
import { ImmutableState } from './types';
import { RegistrationAction } from '@common/registration/actions';
import { RouterAction } from 'connected-react-router';
import { SettingsAction } from './settings/actions';
import Cookie from './lib/Cookie';
import createMiddlewares from './lib/store/createMiddlewares';
import createRootReducer from './reducers';
import createStoreReducers from './lib/store/createReducers';
import reviveStateFromPersistence from './lib/persistence/reviveStateFromPersistence';
import runDefaultActions from './runDefaultActions';
// import reducers from './reducers';

export type Action = AuthAction | CalculatorActions | DashboardActions | SettingsAction | RegistrationAction | RouterAction

interface StoreData {
  cookies: any;
  headers: HeadersInit;
  history: History;
  initialState: typeof ImmutableState;
  persistenceStore: Cookie;
  platformMiddleware: any[];
}

export default function configureStore({
  cookies,
  headers,
  history,
  initialState,
  persistenceStore,
  platformMiddleware,
}: StoreData = {
  cookies: {},
  headers: {},
  history: createBrowserHistory(),
  initialState: ImmutableState,
  persistenceStore: new Cookie({ method: 'GET', // or 'POST', 'PUT', etc.
    headers: {},
    body: null,
    params: {},
    query: {} }),
  platformMiddleware: [],
}) {
  // Browser and Client can inject own middlewares into redux store
  const definedPlatformMiddleware = platformMiddleware || [] as any[];
  // Revive persistence state from cookies (Browser & Server side)
  const initialStateWithPersistence = reviveStateFromPersistence(initialState, persistenceStore);
  // Combine all reducers and enhance them
  const reducers = createStoreReducers<typeof ImmutableState>(createRootReducer(history), persistenceStore);
  // Create store middlewares
  const middlewares = createMiddlewares({
    actionWatchers: {},
    definedPlatformMiddleware,
    cookies,
    headers,
    initialState,
    persistenceStore,
  });
  // Create Store
  const store = createStore<typeof ImmutableState, Action, unknown, unknown>(reducers, initialStateWithPersistence, middlewares);

  // Enable hot reload where available.
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers.
    module.hot.accept('./reducers', () => {
      const createNextRootReducer = require('./reducers'); // eslint-disable-line global-require

      store.replaceReducer(createStoreReducers(createNextRootReducer(history), persistenceStore));
    });
  }

  // Run default actions - log in user, etc.
  runDefaultActions(store.dispatch, persistenceStore);

  return store;
}
