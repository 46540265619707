import * as actions from './actions';
import { Map, Record } from 'immutable';

interface IInitialState {
  // The bank chosen in `/application/bank-identification`
  bank: string | null;
  bankAccount: string | null;
  // Hide `mobilePhone` and `email` fields on `/profile` if the client is a returning client
  isJustRegistered: boolean;
  // Store recaptcha token to be sent to webapi
  recaptchaResponseToken: string;
  registrationData: Record<string, any>
}

const crossCheckInitialState = {
  email: Map({
    error: false,
    edited: false,
  }),
  mobilePhone: Map({
    error: false,
    edited: false,
  }),
  identityCardNumber: Map({
    error: false,
    edited: false,
  }),
  identityDocument: Map({
    error: false,
    edited: false,
  }),
  postalCode: Map({
    error: false,
    edited: false,
  }),
  city: Map({
    error: false,
    edited: false,
  }),
  streetAndHouseFlatNumber: Map({
    error: false,
    edited: false,
  }),
};

export class InitialState extends Record({
  bank: null,
  bankAccount: null,
  isJustRegistered: false,
  recaptchaResponseToken: '',
  registrationData: {},
  crossCheckErrors: Map({ ...crossCheckInitialState }),
}) {
  static fromJS(value: IInitialState): InitialState {
    return new InitialState(value);
  }
}

const initialState = new InitialState();

export default function reducer(state = initialState, action): InitialState {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.SELECT_IDENTIFICATION_BANK:
      return state.set('bank', action.bank);

    case actions.SET_BANK_ACCOUNT:
      return state.set('bankAccount', action.bankAccount);

    case actions.SET_IS_JUST_REGISTERED:
      return state.set('isJustRegistered', action.payload);

    case actions.REGISTRATION_SET_RECAPTCHA_RESPONSE_TOKEN:
      return state.set('recaptchaResponseToken', action.payload);

    case actions.SET_REGISTRATION_DATA:
      return state.set('registrationData', action.payload);

    case actions.DELETE_REGISTRATION_DATA:
      return state.set('registrationData', {});

    case actions.SET_CROSS_CHECK_ERROR:
      return state.setIn(['crossCheckErrors', action.payload, 'error'], true);

    case actions.REMOVE_CROSS_CHECK_ERROR:
      return state.setIn(['crossCheckErrors', action.payload, 'error'], false);

    case actions.SET_CROSS_CHECK_EDITED:
      return state.setIn(['crossCheckErrors', action.payload, 'edited'], true);

    case actions.REMOVE_CROSS_CHECK_EDITED:
      return state.setIn(['crossCheckErrors', action.payload, 'edited'], false);

    case actions.RESET_CROSS_CHECK_ERRORS:
      return state.set('crossCheckErrors', Map(crossCheckInitialState));
  }

  return state;
}
