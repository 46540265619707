import * as R from '../routesList';

import { PropsWithChildren } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { StaticContext, withRouter } from 'react-router';
import AwaitingLogin from '../../dynamic/registration/components/AwaitingLogin';
import MainLayoutDynamic from '../../shared/layouts/MainLayoutDynamic';
import Registration from '../../dynamic/registration';

type Props = PropsWithChildren<RouteComponentProps<any, StaticContext, unknown>> & {
  location: Location;
};

const Router = (props: Props) => {
  const { location } = props;
  const logoUrl = location.pathname === R.REGISTER ? R.ROOT : R.DASHBOARD;

  return (
    <MainLayoutDynamic navShadow logoUrl={logoUrl} linksHidden>
      <Route path={R.REGISTER} exact component={Registration} />
      <Route path={R.AWAITING_LOGIN} exact component={AwaitingLogin} />
      {/* <Route path={R.PROFILE} exact component={Registration} /> */}
      {/* <Route path={R.EMPLOYMENT} exact component={Registration} /> */}
      <Route path={R.APPLICATION_CONFIRM} exact component={Registration} />
      <Route path={R.APPLICATION_BANK_IDENTIFICATION} exact component={Registration} />
      <Route path={R.APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER} exact component={Registration} />
      <Route path={R.DECISION} exact component={Registration} />
      <Route path={R.APPLICATION_CREDIT_CHECK} exact component={Registration} />
      <Route path={R.APPLICATION_PENDING} exact component={Registration} />
      <Route path={R.APPLICATION_FAILED} exact component={Registration} />
    </MainLayoutDynamic>
  );
};

export default withRouter(Router);
