import { ImmutableState } from '@common/types';
/* eslint-disable no-underscore-dangle */
import { createBrowserHistory, History } from 'history';
import { Map } from 'immutable';
import { routerMiddleware } from 'connected-react-router';
import { Store } from 'redux';
import configureCommonStore, { Action } from '@common/configureStore';
import Cookie from '@common/lib/Cookie';
import reducers from '@common/reducers';

declare global {
  interface Window {
    __INITIAL_STATE__: typeof ImmutableState;
  }
}

export default function configureStoreWithHistory(): {
  store: Store<typeof ImmutableState, Action>;
  history: History;
  } {
  const history = createBrowserHistory();
  const store = configureCommonStore({
    cookies: {},
    headers: {
      originHost: window.location.hostname + (window.location.port && `:${window.location.port}`),
    },
    history,
    initialState: window.__INITIAL_STATE__,
    // eslint-disable-line no-underscore-dangle
    persistenceStore: new Cookie({}),
    platformMiddleware: [routerMiddleware(history)],
  });

  return {
    store,
    history,
  };
}
// const { store } = configureStoreWithHistory();
const store = configureStoreWithHistory().store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = Record<keyof ReturnType<typeof reducers>, Map<string, Record<string, any> | string>>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
