import { compose } from 'redux';
import { connectActions } from '@browser/connect';
import { ReactElement } from 'react';
import { useTranslator } from '../../../../localizations';
import classnames from 'classnames';
import injectStyles, { Button } from '4finance-components-pl';
import Modal from '../../../shared/components/Modal';
import QA_CLASSES from '../../../lib/qa';
import styles from './ChangeSuccess.jss';

type Props = {
  classes: Record<string, any>;
  type: string;
  updateClientData: (process: string) => void;
};

const ChangeSuccessModal = ({
  classes,
  type,
  updateClientData,
}: Props) => {
  const {
    msg,
    cnt,
  } = useTranslator();

  const isComingFromApplicationPage = typeof window !== 'undefined' && new URLSearchParams(window.location.search).get('from_application');
  const onClose = () => updateClientData(isComingFromApplicationPage ? 'application' : 'settings');

  const title = cnt(`dashboard.settings.change_${type}.change.title`);
  const text = cnt(`dashboard.settings.change_${type}.success.text`);
  const button = msg(`dashboard.settings.change_${type}.success.button`);

  const chooseClassesBasedOnAttr = (attr: string) => classnames(classes[attr], QA_CLASSES[`CLIENT_CHANGE_SUCCESS_${attr.toUpperCase()}` as keyof typeof QA_CLASSES]);

  return (
    <div className={classes.modalContentWrapper}>
      <Modal open onClose={onClose}>
        <div className={chooseClassesBasedOnAttr('title')}>{title}</div>
        <div className={classes.separator} />
        <div className={chooseClassesBasedOnAttr('text')}>{text}</div>
        <div className={classes.buttonContainer}>
          <Button fullWidth className={chooseClassesBasedOnAttr('button')} onClick={onClose}>
            {button}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

ChangeSuccessModal.styleRoot = 'ChangeSuccessModal';
export default compose<({ type }: {type: string}) => ReactElement>(connectActions({
  updateClientData: ['settings', 'updateClientData'],
}), injectStyles(styles))(ChangeSuccessModal);
