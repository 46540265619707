const ERROR = 'invalid_email';
const REGEX = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+$');

export default function noSpecialCharsInEmail() {
  return (value?: string): string | null => {
    if (value && value.includes('@')) {
      return REGEX.test(value) ? null : ERROR;
    }

    return null;
  };
}
