import { AnyAction } from 'redux';
import { actions as form } from '4finance-onion-form-pl';
import { WebapiError } from '@common/types';
/**
 * Helper that returns an action with API errors extracted from response as payload.
 * Supports an optional mapping object which is used to rename API properties to local properties.
 *
 * @function setFormApiError
 * @param  {String} formName - name of the form - see onion-form
 * @param  {Object} payload - API response
 * @param  {Object} [mapping] - mapping object, e.g. { password: 'oldPassword' } will cause "password" be renamed to "oldPassword"
 * @return {Object} - onion-form wrapped action
 */

export default function setFormApiError(formName: string, payload: {errors: WebapiError[]}, mapping: Record<string, any> = {}): AnyAction {
  if (!payload.errors) {
    return {
      type: 'SET_FORM_API_ERROR_FAILED',
    };
  }

  return form.setMultipleFields(formName, 'apiError', payload.errors.reduce((acc, err) => {
    if (!err.property) throw new Error('missing property');

    return { ...acc,
      [mapping[err.property] || err.property]: err.messageTemplate,
    };
  },
  {}));
}
