import * as auth from '../../authentication/actions';
import * as R from '../../../browser/routes/routesList';
import * as routerFlow from '../routerFlow';
import { actions as api } from '../../api';
import { actions as form, scrollToError } from '4finance-onion-form-pl';
import { ID_CARD_ERROR_MAPPING, TEMPORARY_CONSENTS } from '@common/lib/constants';
import { omit } from 'lodash';
import { PersistentStore, WebapiErrors } from '@common/types';
import { push as updatePath } from 'connected-react-router';
import config from '4finance-configuration-pl';
import generateRandomData from '../generateRandomData';
import getAffiliateInfo from '../../lib/getAffiliateInfo';
import getAnalyticsCookies from '@browser/lib/getAnalyticsCookies';
import getDeviceType from '../../../browser/lib/getDeviceType';
import setFormApiError from '../../lib/setFormApiError';

export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const RANDOM_FILL = 'RANDOM_FILL';
export const SUBMIT_REGISTRATION = 'SUBMIT_REGISTRATION';
export const SET_IS_JUST_REGISTERED = 'SET_IS_JUST_REGISTERED';
export const REGISTRATION_SET_RECAPTCHA_RESPONSE_TOKEN = 'REGISTRATION_SET_RECAPTCHA_RESPONSE_TOKEN';
export const FINALIZE_REGISTRATION = 'FINALIZE_REGISTRATION';
export const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA';
export const DELETE_REGISTRATION_DATA = 'DELETE_REGISTRATION_DATA';
export const SET_CROSS_CHECK_ERROR = 'SET_CROSS_CHECK_ERROR';
export const REMOVE_CROSS_CHECK_ERROR = 'REMOVE_CROSS_CHECK_ERROR';
export const RESET_CROSS_CHECK_ERRORS = 'RESET_CROSS_CHECK_ERRORS';
export const SET_CROSS_CHECK_EDITED = 'SET_CROSS_CHECK_EDITED';
export const REMOVE_CROSS_CHECK_EDITED = 'REMOVE_CROSS_CHECK_EDITED';

export interface SetCrossCheckError {
  type: typeof SET_CROSS_CHECK_ERROR
  payload: string
}

export interface RemoveCrossCheckError {
  type: typeof REMOVE_CROSS_CHECK_ERROR
  payload: string
}

export interface SetCrossCheckEdited {
  type: typeof SET_CROSS_CHECK_EDITED
  payload: string
}
export interface RemoveCrossCheckEdited {
  type: typeof REMOVE_CROSS_CHECK_EDITED
  payload: string
}
export interface ResetCrossCheckErrors {
  type: typeof RESET_CROSS_CHECK_ERRORS
}
export interface SetRecaptchaResponseToken {
  type: typeof REGISTRATION_SET_RECAPTCHA_RESPONSE_TOKEN
  payload: string
}

export type RegistrationAction = SetCrossCheckError | RemoveCrossCheckError | SetCrossCheckEdited | SetRecaptchaResponseToken | RemoveCrossCheckEdited | ResetCrossCheckErrors;

export const extractConsentsFromFormData = (data: Record<string, any>) => Object.keys(data).reduce((acc, item) => {
  if (!(item === 'declaration' || item.includes('accept'))) return acc;

  return { ...acc, [item]: data[item] };
}, {});

export function sendRegistration(params: Record<string, any>) {
  return ({ httpPost, getState }: Record<string, any>) => {
    const { recaptchaResponseToken } = getState().registration;
    const tokenString = recaptchaResponseToken ? `?responseToken=${recaptchaResponseToken}` : '';

    return {
      type: 'SEND_REGISTRATION',
      meta: {
        params,
      },
      payload: {
        promise: httpPost(`/registration${tokenString}`, params),
      },
    };
  };
}
export function submitRegistration(data: Record<string, any>) {
  return ({ dispatch, persistenceStore }: Record<string, any>) => {
    async function getPromise() {
      const analyticsCookies = getAnalyticsCookies(persistenceStore);
      const params = {
        ...data,
        identityDocumentData: {
          documentNumber: data.identityCardNumber,
          countryCode: 'PL',
          type: 'ID_CARD',
        },
        citizenship: 'PL',
        passwordRepeat: data.password,
        affiliateInfo: getAffiliateInfo(),
        analyticsCookies,
        ...TEMPORARY_CONSENTS,
      };

      delete params.identityCardNumber;

      const { payload, error } = await dispatch(sendRegistration(params));

      if (error) {
        const ERRORS_TO_BE_MODIFIED = [
          payload.errors.filter((err: Record<string, any>) => err.property === 'personalId')[0],
          payload.errors.filter((err: Record<string, any>) => err.property === 'mobilePhone')[0],
          payload.errors.filter((err: Record<string, any>) => err.property === 'email')[0],
        ].filter(err => err !== undefined);

        dispatch(setFormApiError('registration', payload, ID_CARD_ERROR_MAPPING));

        // modifying the 'other' errors displayed for the three fields to be more specific
        if (ERRORS_TO_BE_MODIFIED.length > 0) {
          ERRORS_TO_BE_MODIFIED.forEach(err => {
            if (err.messageTemplate === 'other') {
              dispatch(form.setFormFieldProperty('registration', err.property, 'apiError', `${err.property}_other`));
            }
          });
        }

        scrollToError();
        throw Error('Submit registration failed');
      }
      await dispatch(setRegistrationData(data));
      dispatch(updatePath(R.AWAITING_LOGIN));
    }

    return {
      type: SUBMIT_REGISTRATION,
      payload: getPromise(),
    };
  };
}

export function finalizeRegistration(responseToken: string) {
  return ({ dispatch, getState }: Record<string, any>) => {
    async function getPromise() {
      const data = getState().registration.get('registrationData');

      const { email, password } = data;
      const { error: loginError } = await dispatch(auth.requestLogin({
        username: email,
        password,
        isRegistration: true,
        responseToken,
      }));

      if (loginError) {
        throw Error('Login failed');
      }

      const consents = extractConsentsFromFormData(data);
      const { error: createApplicationError } = await dispatch(createApplication({ consents }));

      if (createApplicationError) {
        throw Error('Failed to create an application after registration');
      }

      await dispatch(deleteRegistrationData());
      await dispatch(api.fetchClient());
      dispatch(setIsJustRegistered(true));
    }

    return {
      type: FINALIZE_REGISTRATION,
      payload: getPromise(),
    };
  };
}
export function updateProfile(data: Record<string, any>) {
  return async ({ dispatch }: Record<string, any>) => {
    const { error, payload } = await dispatch(api.patchClientProfile(data));

    if (error) {
      dispatch(setFormApiError('profile', payload, ID_CARD_ERROR_MAPPING));
      throw new Error('Unable to update client profile.');
    }

    dispatch(updatePath(R.APPLICATION_CONFIRM));
  };
}
export function updateEmploymentInfo(data: Record<string, any>) {
  return async ({ dispatch }: Record<string, any>): Promise<void> => {
    const { employmentStartMonth, employmentStartYear, employmentStatus } = data;
    const startMonth = employmentStartMonth <= 9 ? `0${employmentStartMonth}` : employmentStartMonth;
    const employmentStartDate =
      employmentStartYear && employmentStartMonth ? `${employmentStartYear}-${startMonth}` : null;
    const requestPayload = {
      employmentData: {
        employerName: data.employerName || null,
        employerIndustry: data.employerIndustry || null,
        employmentStatus,
        employmentStartDate,
      },
      budgetData: {
        totalIncome: data.totalIncome.replace(',', '.'),
        totalExpense: data.totalExpense.replace(',', '.'),
      },
      householdData: {
        numberOfInhabitants: data.numberOfInhabitants,
      },
    };
    const { error, payload } = await dispatch(api.patchClientAdditionalData(requestPayload));

    if (error) {
      dispatch(setFormApiError('employment', payload));
      throw new Error('Unable to update client employment.');
    }

    dispatch(updatePath(R.APPLICATION_CONFIRM));
  };
}
export function createApplication({ id = 'default', consents }: { id?: string, consents: Record<string, any> }) {
  return ({ dispatch, getState }: Record<string, any>): Record<string, any> => {
    async function getPromise() {
      const { amount, term } = getState().calculator[id].toJS();

      if (!amount || !term) {
        throw new Error('Unable to create loan application.');
      }

      const source = getDeviceType().toUpperCase();
      const application = {
        amount,
        term,
        source,
        loanPurpose: 'default',
        affiliateInfo: getAffiliateInfo(),
        declaration: !consents.declaration ? null : consents.declaration,
      };
      const { error } = await dispatch(api.sendClientApplication(application));

      if (error) {
        throw dispatch(updatePath(R.APPLICATION_FAILED));
      }

      const consentsWithoutDeclaration = omit(consents, 'declaration');

      dispatch(updateApplication(consentsWithoutDeclaration));
    }

    return {
      type: CREATE_APPLICATION,
      payload: getPromise(),
    };
  };
}
export function updateApplication(data: Record<string, any>) {
  return async ({ dispatch, getApiResponse }: PersistentStore) => {
    const { payload }: {payload: WebapiErrors & {rejected: boolean}} = await dispatch(api.patchClientApplication({ ...data, ...TEMPORARY_CONSENTS }));

    if (payload?.errors) {
      payload.errors.map((error) => dispatch(setCrossCheckError(error.property)));

      dispatch(updatePath(`${R.APPLICATION_CHANGE_DATA}?from_application=1`));

      dispatch(setFormApiError('confirmApplication', payload));
      throw new Error('Unable to update client application.');
    }

    dispatch(updatePath(await routerFlow.getNextRouteAfterApplicationConfirm(getApiResponse)));
  };
}
export function populateProfileForm() {
  return async ({ getApiResponse, dispatch }: Record<string, any>) => {
    const {
      email: { email },
      mobilePhone: { mobilePhone },
      declaredAddress: { fullAddress, postalCode, location1 },
    } = await getApiResponse(['fetch', 'client']);

    const { documentNumber } = await getApiResponse(['fetch', 'client', 'identityDocument']);

    const values = {
      email,
      mobilePhone,
      identityCardNumber: documentNumber,
      streetAndHouseFlatNumber: fullAddress,
      postalCode,
      city: location1,
    };

    // TODO: check what's wrong with these multiple arguments in a one-argument form function
    dispatch(form.setMultipleFields('profile', 'value', values));
  };
}
export function populateEmploymentForm() {
  return async ({ getApiResponse, dispatch }: Record<string, any>) => {
    const { employmentData, budgetData, householdData } = await getApiResponse(['fetch', 'client', 'additionalData']);

    if (!employmentData) {
      return;
    }

    const budgetValues = Object.keys(budgetData).reduce(
      (all, k) => ({ ...all, [k]: budgetData[k]?.toString() || '' }),
      {},
    );

    const fields = { ...budgetValues, ...householdData, ...omit(employmentData, ['employmentStartDate']) };
    const { employmentStartDate } = employmentData;

    fields.employmentStartYear = employmentStartDate.split('-')[0] || '';
    fields.employmentStartMonth = Number(employmentStartDate.split('-')[1]).toString() || ''; // Number.toString to remove lead zeros

    dispatch(form.setMultipleFields('employment', 'value', fields));
  };
}
type FormName = 'registration' | 'profile' | 'employment';
export function randomFill(formNames: FormName[] = ['registration', 'profile', 'employment']) {
  return ({ dispatch }: Record<string, any>) => {
    if (!config.faker.enabled) {
      return {
        type: 'DISABLED_RANDOM_FILL',
      };
    }

    formNames.forEach((n) => {
      dispatch(form.setMultipleFields(n, 'value', generateRandomData(n)));
      dispatch(form.clearFormProperty(n, 'error'));
      dispatch(form.clearFormProperty(n, 'apiError'));
    });

    return {
      type: RANDOM_FILL,
    };
  };
}
export function setIsJustRegistered(payload: boolean) {
  return {
    type: SET_IS_JUST_REGISTERED,
    payload,
  };
}
export function setCrossCheckError(propertyName: string): SetCrossCheckError {
  return {
    type: SET_CROSS_CHECK_ERROR,
    payload: propertyName,
  };
}

export function removeCrossCheckError(propertyName: string): RemoveCrossCheckError {
  return {
    type: REMOVE_CROSS_CHECK_ERROR,
    payload: propertyName,
  };
}

export function setCrossCheckEdited(propertyName: string): SetCrossCheckEdited {
  return {
    type: SET_CROSS_CHECK_EDITED,
    payload: propertyName,
  };
}

export function removeCrossCheckEdited(propertyName: string) {
  return {
    type: REMOVE_CROSS_CHECK_EDITED,
    payload: propertyName,
  };
}

export function resetCrossCheckErrors(): ResetCrossCheckErrors {
  return {
    type: RESET_CROSS_CHECK_ERRORS,
  };
}

export function setRecaptchaResponseToken(responseToken: string): SetRecaptchaResponseToken {
  return {
    type: REGISTRATION_SET_RECAPTCHA_RESPONSE_TOKEN,
    payload: responseToken,
  };
}

export function setRegistrationData(data: Record<string, any>) {
  return {
    type: SET_REGISTRATION_DATA,
    payload: data,
  };
}

export function deleteRegistrationData() {
  return {
    type: DELETE_REGISTRATION_DATA,
  };
}
