import { Action, combineReducers, Reducer, Store } from 'redux';
import { AUTHENTICATION_REQUEST_LOGOUT } from '../../authentication/actions';
import { TypedMap } from '@common/types';
import config from '4finance-configuration-pl';
import logSlowReducers from './logSlowReducers';
import recycle from 'redux-recycle';
import reviveStateFromPersistence from '../persistence/reviveStateFromPersistence';

const PERSISTENT_REDUCERS = ['device', 'router', 'translate', 'apiUrls'];

export default function createStoreReducers<S extends TypedMap<string, Record<string, any>>, A extends Action>(appReducers: Store, persistenceStore: Store): Reducer<Store | S> {
  const combinedReducers = config.debug.logSlowReducers ? combineReducers(logSlowReducers(appReducers, 4)) : combineReducers(appReducers);

  if (typeof appReducers === 'undefined') throw new Error('no store found');

  return recycle<S | Store>(combinedReducers, [`${AUTHENTICATION_REQUEST_LOGOUT}_SUCCESS`], state => {
    const newState = PERSISTENT_REDUCERS.reduce((acc, key) => ({ ...acc,
      [key]: state[key],
    }), {});

    return reviveStateFromPersistence(newState, persistenceStore);
  });
}
