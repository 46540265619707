import { modalContent } from '../Modal/Modal.jss';
import { StylesheetParams } from '@common/types';

export default ({ colors, mediaQueries }: StylesheetParams) => ({
  modal: {
    width: '540px',
    [mediaQueries.breakpointTablet]: {
      width: '100%',
    },
  },

  inputWrapper: {
    [mediaQueries.breakpointTablet]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },

  title: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    marginBottom: '15px',
    fontSize: '29px',
    color: colors.darkGray,
    textAlign: 'center',
    fontWeight: 500,

    [mediaQueries.breakpointTablet]: {
      marginBottom: '20px',
      fontSize: '20px',
    },
  },

  modalContentWrapper: {
    ...modalContent,
  },

  description: {
    margin: '10px 20px',

    [mediaQueries.breakpointTablet]: {
      margin: '5px',
    },
  },

  errorList: {
    fontWeight: 500,
    listStyleType: 'disc',
    margin: '10px 0 45px 0',
    width: '100%',
    maxWidth: '460px',
    backgroundColor: colors.white,
    borderRadius: '5px',
    padding: '10px 30px',

    [mediaQueries.breakpointSmall]: {
      padding: '6px 20px',
      maxWidth: 'initial',
      margin: '0 0 45px 0',
      borderRadius: 0,
    },
  },

  errorItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '90px',
    borderBottom: `1px solid ${colors.monotone2}`,

    '&:last-child': {
      borderBottom: 'none',
    },
  },

  editButton: {
    width: '140px',
    minWidth: '140px',
    height: '40px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 450,

    [mediaQueries.breakpointSmall]: {
      fontSize: '13px',
      height: '34px',
      width: '80px',
      minWidth: '80px',
    },
  },

  editButtonEdited: {
    border: `1px solid ${colors.secondary}`,
    backgroundColor: colors.white,
    color: colors.secondary,
    textTransform: 'uppercase',

    '& svg': {
      height: '15px',
      width: '15px',
      marginRight: '10px',
      fill: colors.primary,
      '& path': {
        fill: colors.primary,
      },

      [mediaQueries.breakpointSmall]: {
        display: 'none',
      },
    },

    '& p': {
      margin: 0,
    },
  },

  closeButton: {
    backgroundColor: colors.primary,
  },

  dataWrapper: {
    width: 'calc(100% - 140px)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [mediaQueries.breakpointSmall]: {
      width: 'calc(100% - 110px)',
      minWidth: '150px',
    },
  },

  label: {
    margin: 0,
    // color: colors.monotone6,
    fontSize: '15px',
  },

  smallFont: {
    fontSize: '15px',
  },

  data: {
    fontSize: '19px',
    color: colors.rejectRed,
    fontWeight: 500,
    margin: '5px 0 0 0',
    '&$correct': {
      color: colors.black,
    },
  },

  correct: {},

  noMarginBottom: {
    marginBottom: 0,
  },
  icon: {
    '& svg': {
      '& *': {
        fill: 'black',
      },
    },
    width: '80px',
    height: '80px',
    margin: '10px 0 20px 0',
  },
});
