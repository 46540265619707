import * as R from '@routes/routesList';
import { Action } from './configureStore';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AsyncActionCreator } from '@common/settings/actions';
import { fromJS, Map, Record } from 'immutable';
import { RootState } from '@browser/configureStoreWithHistory';
import { ValuesType } from 'utility-types';
import colors from '@browser/lib/styles/colors.jss';
import Cookie from '@common/lib/Cookie';

export type TypedMap<K extends string | number | symbol, V extends Record<string, unknown>> = Map<K, V>;

export const createTypedMap = <DataType extends Record<string, any>>(data: DataType): TypedMap<keyof DataType, ValuesType<DataType>> => Map(data);

const QueryContainer = Record({
  data: fromJS({}),
  errors: null,
  pending: false,
  fetched: false,
});

export default function APIQuery(input = { data: {} }) {
  return new QueryContainer({ ...input, data: fromJS(input.data) });
}

export type RouteAction = ActionCreatorWithPayload<ValuesType<typeof R>>

export interface ActionWithPayload<T> {
  payload: T;
  error?: Error | string | null;
}

export type DispatchType<T> = (args: any) => ActionWithPayload<T>;

interface IAmountData {
  amount: number | null;
  monthlyPayment: number | null;
  term: string | null;
}

interface IQuery {
  errors: string | null;
  pending: boolean;
  fetched: boolean;
}

const AmountData = createTypedMap({
  amount: null,
  monthlyPayment: null,
  term: null,
});

export interface State {
  api: {
    fetch: {
      application: {
        constraints: {
          query: IQuery;
          firstLoanOffer: Record<string, any>;
        };
      };
      client: {
        settings: {
          query: IQuery;
        };
        loans: Record<string, any>;
        query: IQuery;
        profile: {
          query: IQuery;
        };
        phone: {
          query: IQuery;
        };
        application: {
          constraints: {
            query: IQuery;
          };
        };
        additionalData: {
          query: IQuery;
        };
        banks: {
          query: IQuery;
        };
        consents: {
          query: IQuery;
        };
        bankAccountNumber: {
          query: IQuery;
        };
        email: {
          query: IQuery;
        };
      };
      dictionary: {
        countries: {
          query: IQuery;
        };
        employmentStatuses: {
          query: IQuery;
        };
        industries: {
          query: IQuery;
        };
      };
      registration: {
        query: IQuery;
      };
    };
    patch: {
      client: {
        settings: {
          query: IQuery;
        };
        phoneConfirmation: {
          query: IQuery;
        };
        profile: {
          query: IQuery;
        };
        phone: {
          query: IQuery;
        };
        application: {
          check: {
            query: IQuery;
          };
          query: IQuery;
        };
        applicationData: {
          query: IQuery;
        };
        emailConfirmation: {
          query: IQuery;
        };
        bankAccountNumber: {
          query: IQuery;
        };
        password: {
          query: IQuery;
        };
      };
    };
    send: {
      client: {
        application: {
          query: IQuery;
        };
        cookies: {
          query: IQuery;
        };
        email: {
          query: IQuery;
        };
        phone: {
          query: IQuery;
        };
      };
      registration: {
        query: IQuery;
      };
    };
  };
  apiUrls: {
    backoffice: string;
    webapi: string;
  };
  authentication: {
    isLoggedIn: boolean;
    loginError: string | null;
  };
  calculator: {
    default: IAmountData;
    additionalAmount: IAmountData;
    proposal: IAmountData;
    monthlyPaymentInterval: Record<string, any>;
  };
  dashboard: {
    selectedPayment: string;
  };
  onionForm: {
    fields: Record<string, any>;
  };
  pendingActions: [];
  persistence: Record<string, any>;
  registration: {
    bank: string | null;
    bankAccount: string | null;
    isJustRegistered: boolean;
    recaptchaResponseToken: string;
  };
  router: {
    location: {
      pathname: string;
      search: string;
      hash: string;
      key: string;
    };
    action: string;
  };
  settings: {
    recaptchaResponseToken: string;
    bank: string | null;
    messages: [];
  };
}

// interface StateMap<K, V> extends Map<K, V> {
//   toJS(): State;
// }

export const ImmutableState = createTypedMap({
  api: {
    fetch: {
      application: {
        constraints: {
          query: APIQuery,
          firstLoanOffer: {},
        },
      },
      client: {
        settings: {
          query: APIQuery,
        },
        loans: {},
        query: APIQuery,
        profile: {
          query: APIQuery,
        },
        phone: {
          query: APIQuery,
        },
        application: {
          constraints: {
            query: APIQuery,
          },
        },
        additionalData: {
          query: APIQuery,
        },
        banks: {
          query: APIQuery,
        },
        consents: {
          query: APIQuery,
        },
        bankAccountNumber: {
          query: APIQuery,
        },
        email: {
          query: APIQuery,
        },
      },
      dictionary: {
        countries: {
          query: APIQuery,
        },
        employmentStatuses: {
          query: APIQuery,
        },
        industries: {
          query: APIQuery,
        },
      },
      registration: {
        query: APIQuery,
      },
    },
    patch: {
      client: {
        settings: {
          query: APIQuery,
        },
        phoneConfirmation: {
          query: APIQuery,
        },
        profile: {
          query: APIQuery,
        },
        phone: {
          query: APIQuery,
        },
        application: {
          check: {
            query: APIQuery,
          },
          query: APIQuery,
        },
        applicationData: {
          query: APIQuery,
        },
        emailConfirmation: {
          query: APIQuery,
        },
        bankAccountNumber: {
          query: APIQuery,
        },
        password: {
          query: APIQuery,
        },
      },
    },
    send: {
      client: {
        application: {
          query: APIQuery,
        },
        cookies: {
          query: APIQuery,
        },
        email: {
          query: APIQuery,
        },
        phone: {
          query: APIQuery,
        },
      },
      registration: {
        query: APIQuery,
      },
    },
  },
  apiUrls: {
    backoffice: '',
    webapi: '',
  },
  authentication: {
    isLoggedIn: false,
    loginError: null,
  },
  calculator: {
    default: AmountData,
    additionalAmount: AmountData,
    proposal: AmountData,
    monthlyPaymentInterval: {},
  },
  dashboard: {
    selectedPayment: '',
  },
  onionForm: {
    fields: {},
  },
  pendingActions: [],
  persistence: {},
  registration: {
    bank: '',
    bankAccount: '',
    isJustRegistered: false,
    recaptchaResponseToken: '',
  },
  router: {
    location: {
      pathname: '',
      search: '',
      hash: '',
      key: '',
    },
    action: '',
  },
  settings: {
    bank: '',
    messages: [],
    recaptchaResponseToken: '',
  },
});

type Colors = typeof colors;

export type StylesheetParams = {
  colors: {
    primary: string;
    primaryLight: string;
    secondary: string;
    primaryDarker: string;
    secondaryDarker: string;
    info: string;
    warning: string;
    error: string;
    neutral: string;
    success: string;
    black: string;
    grayDarker: string;
    grayDark: string;
    gray: string;
    grayLight: string;
    grayLighter: string;
    grayLightest: string;
    monotone1: string;
    monotone2: string;
    monotone3: string;
    monotone4: string;
    monotone5: string;
    white: string;
    border: string;
    yellowDark: string;
    yellowLight: string;
    textPrimary: string;
    textSecondary: string;
    background: string;
    backgroundDarker: string;
    backgroundFooter: string;
    link: string;
  } & Colors;

  mediaQueries: {
    breakpointLarge: string;
    breakpointLaptop: string;
    breakpointLaptopSmall: string;
    breakpointTablet: string;
    breakpointSmall: string;
    retina: string;
    smallScreen: string;
    laptop: string;
    laptopSmall: string;
    tablet: string;
    wideDesktop: string;
  };

  mobileFirstMediaQueries: {
    mobile: string;
    tablet: string;
    laptop: string;
    desktop: string;
    desktopLarge: string;
  };

  sizes: {
    inputHeight: string,
    container: string,
    infoContainer: string,
    gutterWidth: number,
    columns: number,
  };

  borders: {
    calculator: number;
  };

  containers: {
    tablet: number;
    laptop: number;
    desktop: number;
  },
};

export type Classes<T extends (...args: any[]) => any> = Record<keyof ReturnType<T>, string>;

// TODO: refactor types with middleware typings when Immutable typings become available

export type Params = Partial<Consents | SettingsParams> | Record<string, any>;

export type PersistentStore = {
  cookies: Cookie;
  httpDelete: (path: string) => Record<string, any>;
  httpPost: (path: string, params: Params) => (persistentStore: PersistentStore) => Promise<void>;
  httpPut: (path: string, params: Params) => (persistentStore: PersistentStore) => Promise<void>;
  httpGet: (path: string) => Record<string, any>;
  httpGetHtml: (path: string) => Record<string, any>;
  httpRequest: (path: string) => Record<string, any>;
  // dispatchAndThrow: (action, error) => {
  //   dispatch(action);
  //   throw error;
  // },
  // dispatchPromise: action => dispatch(action).payload.promise.then(throwAfterPromiseAction).catch(e => {
  //   console.log(e);
  //   throw new Error('dispatch promise failed'); // eslint-disable-line
  // }),
  getApiResponse: (
    path: string | string[],
    {
      dynamicKeys,
      forceFetch,
      actionName,
    }: {
      dynamicKeys?: boolean;
      forceFetch?: boolean;
      actionName?: string;
    },
  ) => string | Record<string, any>;
  getState: () => RootState;
  dispatch: <T extends Action | AsyncActionCreator<any>>(action: T) => T & (Promise<((persistenceStore: PersistentStore) => <TT extends Action>(nestedAction: TT) => TT) & {payload: any, error: unknown}>);
};

export interface Consents {
  accepNews: boolean;
  acceptMarketingEmails: boolean;
  acceptMarketingSms: boolean;
  acceptMarketingCalls: boolean;
  acceptProfiling: boolean;
  acceptDataSharing: boolean;
  acceptInternalDataSharing: boolean;
}

export interface Address {
  location1: string;
  location2: string;
  location3: string;
  location4: string;
  location5: string;
  location6: string;
  postalCode: string;
  postalCodePrefix: string;
  fullAddress: string;
}

export interface SettingsParams extends Consents {
  identified?: boolean;
  invoiceAddress?: Address;
  declaredAddress?: Address;
  identityCardNumber?: string;
}

export type WebapiError = {
  message: string;
  messageTemplate: string;
  property: string;
  source: string;
};

export type WebapiErrors = {
  errors: WebapiError[];
};
